import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/MdxPageLayout/index.jsx";
import ComparisonResultsPageHero from "../../components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../components/ComparisonResultHeroUserLocation";
import BusinessPasswordManagerIllustration from "../../../svg/ComparisonResultPageIllustrations/passwordmanagers.svg";
import DashLaneSVG from "../../../svg/ComparisonResultGridLogos/businesspasswordmanagers/dashlane.svg";
import KeeperSVG from "../../../svg/ComparisonResultGridLogos/businesspasswordmanagers/keeper.svg";
import LastpassSVG from "../../../svg/ComparisonResultGridLogos/businesspasswordmanagers/lastpass.svg";
import BitWardenSVG from "../../../svg/ComparisonResultGridLogos/businesspasswordmanagers/bitwarden.svg";
import OnePasswordSVG from "../../../svg/ComparisonResultGridLogos/businesspasswordmanagers/onepassword.svg";
export const pageMetaData = {
  pageTitle: "Compare Businss Password Managers",
  pageDescription: "Subscriptions From $5 Per Month",
  pageImagePath: "/businesspasswordmanagement.jpg",
  showNavbar: false,
  pageMetaTags: []
};
export const resultGridConfig = {
  columnConfiguration: {
    columns: [{
      index: 1,
      rows: [{
        index: 1,
        text: "Starting Price"
      }, {
        index: 2,
        text: "Product Name"
      }, {
        index: 3,
        text: "30 Day Fee Trial"
      }, {
        index: 4,
        text: "Premium Price"
      }, {
        index: 5,
        text: "Compatiability"
      }, {
        index: 6,
        text: "About"
      }],
      expansionRows: []
    }, {
      index: 2,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Dashlane",
          subText: "",
          imageKey: "dashlane"
        }
      }, {
        index: 2,
        text: "5.00",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per User/Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "Business Plan"
      }, {
        index: 4,
        useIcon: true,
        icon: "tick"
      }, {
        index: 5,
        text: "$8 (Per User/Month)"
      }, {
        index: 6,
        text: "Windows, Mac, Android, iOS"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "Whatever you save in the vault is protected by the latest 256-bit AES encryption. And Dashlane also allows users to set two-factor authentication for their accounts or specific entries."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.dashlane.com/business/pricing",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Dashlane",
        showIcon: false
      }
    }, {
      index: 3,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "Keeper Security",
          subText: "",
          imageKey: "keeper"
        }
      }, {
        index: 2,
        text: "5.00",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per User/Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "Enterprise Plan"
      }, {
        index: 4,
        useIcon: true,
        icon: "tick"
      }, {
        index: 5,
        text: "$5 (Per User/Month)"
      }, {
        index: 6,
        text: "Windows, Mac, Linux, Android, iOS"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "While a paid add-on, Keeper’s KeeperChat feature is a fantastic little module ensures that you can communicate with others on an encrypted messaging platform."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.keepersecurity.com/pricing-personal.html?t=p",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to Keeper Security",
        showIcon: false
      }
    }, {
      index: 4,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "LastPass",
          subText: "",
          imageKey: "lastpass"
        }
      }, {
        index: 2,
        text: "6.00",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per User/Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 4
      }, {
        index: 3,
        text: "Enterprise Plan"
      }, {
        index: 4,
        useIcon: true,
        icon: "tick"
      }, {
        index: 5,
        text: "$8 (Per User)"
      }, {
        index: 6,
        text: "Windows, Mac, Android, iOS"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "Strong password generator.|Compatible with all devices and browsers.|Audit report.|Easy sharing with other users"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://www.lastpass.com/pricing",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to LastPass",
        showIcon: false
      }
    }, {
      index: 5,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "BitWarden",
          subText: "",
          imageKey: "bit-warden"
        }
      }, {
        index: 2,
        text: "3.00",
        isPriceCell: true,
        showSavingText: true,
        savingText: "Per User/Month",
        disableSavingStrikeThrough: true,
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "Enterprise Plan"
      }, {
        index: 4,
        useIcon: true,
        icon: "tick"
      }, {
        index: 5,
        text: "$5 (Per User/Per Month)"
      }, {
        index: 6,
        text: "Windows, Mac, Android, iOS"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "Features a password analyzer that you can use in regular intervals to scrutinize weak and duplicate passwords"
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://bitwarden.com/pricing/",
        openLinkExternally: true,
        text: "Sign Up",
        title: "Click to sign up to BitWarden",
        showIcon: false
      }
    }, {
      index: 6,
      isComingSoon: false,
      rows: [{
        index: 1,
        isHeadingCell: true,
        displayHeadingCell: true,
        heading: {
          text: "1Password",
          subText: "",
          imageKey: "one-password"
        }
      }, {
        index: 2,
        text: "3.99",
        isPriceCell: true,
        showSavingText: true,
        disableSavingStrikeThrough: true,
        savingText: "Per User/Month",
        showRating: true,
        rating: 3
      }, {
        index: 3,
        text: "Teams Plan"
      }, {
        index: 4,
        useIcon: true,
        icon: "tick"
      }, {
        index: 5,
        text: "$7.99 (Per User/Month)"
      }, {
        index: 6,
        text: "Windows, Mac, Android, iOS"
      }, {
        index: 7,
        usePopover: true,
        popOverTitle: "About",
        popOverContents: "1Password also Active Directory integration and Slack integration so that passwords can be securely shared across groups. Admins also get access to activity logs and usage reports which you a wider picture of your organization’s security."
      }],
      expansionRows: [],
      claimButtonConfiguration: {
        targetUrl: "https://1password.com/teams/pricing/",
        openLinkExternally: false,
        text: "Sign Up",
        title: "Click to sign up to 1Password",
        showIcon: false
      }
    }]
  }
};
export const _frontmatter = {};
const layoutProps = {
  pageMetaData,
  resultGridConfig,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <ComparisonResultsPageHero mdxType="ComparisonResultsPageHero">
      <h1>{`Compare business password managers`}</h1>
      <ComparisonResultPageTextWithImageSection images={[<BusinessPasswordManagerIllustration imagekey="large-view" className="h-56 ml-auto" mdxType="BusinessPasswordManagerIllustration" />, <BusinessPasswordManagerIllustration imagekey="mobile-view" className="h-56 w-full" mdxType="BusinessPasswordManagerIllustration" />]} mdxType="ComparisonResultPageTextWithImageSection">
        <p>{`Waiting on copy from Steady Content as I can't think of anything relevant to say so saying nothing at all is probably best.`}</p>
      </ComparisonResultPageTextWithImageSection>
      <ComparisonResultHeroUserLocation productCategory="Business Password Managers" mdxType="ComparisonResultHeroUserLocation" />
    </ComparisonResultsPageHero>
    <ComparisonResultGrid columnConfiguration={resultGridConfig.columnConfiguration} mdxType="ComparisonResultGrid">
  <DashLaneSVG className="w-full" imagekey="dashlane" mdxType="DashLaneSVG" />
  <KeeperSVG className="w-full" imagekey="keeper" mdxType="KeeperSVG" />
  <LastpassSVG className="w-full" imagekey="lastpass" mdxType="LastpassSVG" />
  <BitWardenSVG className="w-full" imagekey="bit-warden" mdxType="BitWardenSVG" />
  <OnePasswordSVG className="w-full" imagekey="one-password" mdxType="OnePasswordSVG" />
    </ComparisonResultGrid>
    <ComparisonResultColumnContent blogContentCategory="security" mdxType="ComparisonResultColumnContent">
      <ComparisonResultColumnContentBackgroundContainer mdxType="ComparisonResultColumnContentBackgroundContainer">
        <h2>{`How We Ranked`}</h2>
        <h3>{`Comparing Services by Their Ratings`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultColumnContentBackgroundContainer>
      <ComparisonResultGettingStartedBlock mdxType="ComparisonResultGettingStartedBlock" />
      <ComparisonPageRecommendations requestingProductCategory="business password managers" mdxType="ComparisonPageRecommendations" />
      <ComparisonResultPageLearnMoreContainer mdxType="ComparisonResultPageLearnMoreContainer">
        <h3>{`Learn More`}</h3>
        <p>{`Waiting for content from Steady Content.`}</p>
      </ComparisonResultPageLearnMoreContainer>
    </ComparisonResultColumnContent>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      